import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.initialisePlayer()
  }

  initialisePlayer() {
    const facade = this.data.get('facade');
    const shadowRoot = this.element.shadowRoot
    const style = document.createElement( 'style' )
    style.innerHTML = `

      #frame:hover .lvo-playbtn { background-color: #FF4900; opacity: 1; cursor: pointer; }
      #frame { background-image: url('${facade}'); background-size: cover;}
      #frame.lvo-activated   {background: none;}
      #frame::before {background-image: none}
    `
    shadowRoot.appendChild( style )

  }
}




import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['takeover']

  openDialog(event) {
    const scrollY = window.scrollY
    if (event !== undefined) event.preventDefault()
    document.body.classList.add("takeover-open")
    document.body.style.top = `-${scrollY}px`
    this.takeoverTarget.classList.add("state--open")

  }

  closeDialog(event) {
    event.preventDefault();
    const scrollY = document.body.style.top
    const scrollTo = parseInt(scrollY || '0') * -1
    document.body.style.top = '';
    document.body.classList.remove("takeover-open")
    window.scrollTo(0, scrollTo)
    this.takeoverTarget.classList.remove("state--open")
  }

  toggleDialog(event) {
    if (event !== undefined) event.preventDefault();
    document.body.classList.toggle("takeover-open");
    this.takeoverTarget.classList.toggle("state--open")
  }

}

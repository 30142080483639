import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "revealableContent" ]

  toggle_revealed(event) {
    event.currentTarget.classList.toggle("state--revealed")
    this.revealableContentTargets.forEach((target) => {
      if(target.id == event.currentTarget.getAttribute('data-reveals')){
        target.classList.toggle("hidden")
      }
      if(target.classList.contains(event.currentTarget.getAttribute('data-reveals'))){
        target.classList.toggle("hidden")
      }
    })
  }

}

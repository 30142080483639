// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("trix")
// require("@rails/actiontext")
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import '@slightlyoff/lite-vimeo';
import { Application } from "stimulus"
import RevealableController from "controllers/revealable_controller"
import ViewportEntranceToggleController from "controllers/viewport_entrance_toggle_controller"
import TakeoverController           from "controllers/takeover_controller"
import VimeoController           from "controllers/vimeo_controller"

const application = Application.start()
application.register("revealable", RevealableController)
application.register("viewport-entrance-toggle", ViewportEntranceToggleController)
application.register("takeover", TakeoverController)
application.register("vimeo", VimeoController)
